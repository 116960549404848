// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NpsEnvironmentConfig } from "./nps-environment-config";

export const npsEnvironment: NpsEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGPROD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGST': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'PowerCo': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGITC': {
      url: 'https://api-d.azuf.apps.vwgroup.com/nps/api',
      clientId: '446f3abd-5dee-416c-b269-2a0102380d22',
      authority: 'https://login.microsoftonline.com/9f69edf3-ef71-467c-8885-5e380cdc20cd',
      scopes: ['api://446f3abd-5dee-416c-b269-2a0102380d22/API']
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
